import React from 'react';
import styled from '@emotion/styled';
import config from '../../config/site';
import { Layout, Container } from 'layouts';
import { Header, Title, SEO } from 'components';
import i18n from '../i18n/i18n';


const CardsWrapper = styled.div`
  margin-bottom: 8rem;

  @media (max-width: 700px) {
    margin-bottom: 0;
  }
`;

const PastCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -2rem;

  @media (max-width: 1000px) {
    margin: 0 2rem;
    justify-content: space-between;
  }
  @media (max-width: 700px) {
    margin: 4rem 1rem 1rem 1rem;
  }
`;

const Events = ({
  pageContext: {
    locale, // イベントページは国際化対応しないが文言表示にはi18nを使うのでlocaleを使う
    defaultSlug,
    supportedLangs,
  }
}) => {

  i18n.changeLanguage(locale)

  return (
    <Layout
      locale={locale}
      defaultSlug={defaultSlug}
      supportedLangs={supportedLangs}
    >
      <SEO
        title={`Events - ${config[locale].title}`}
        desc={i18n.t("eventsPage.description")}
        pathname="/events"
        article
      />
      <Header title={i18n.t("eventsPage.title")} />
      <Container type="big">
        <Title title={i18n.t("upcomingEventsTitle")} />
          <CardsWrapper>
            <div>{i18n.t("noUpcomingEvent")}</div>
          </CardsWrapper>
        <Title title={i18n.t("pastEventsTitle")} />
          <PastCardsWrapper>
            <div>{i18n.t("noPastEvent")}</div>
          </PastCardsWrapper>
      </Container>
    </Layout>
  );
}

export default Events;
